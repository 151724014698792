const WF_CLS = "ne-wf-ifr";
const WF_ID = "nestegg-wf-ifr";
const WF_TOKEN = "wft";
const WF_DOC_TYPE = "wfdt";

let embedInterval = null;

const createEmbedInterval = (postEmbedMessage) => {
    embedInterval = setInterval(postEmbedMessage, 1000);
};

const clearEmbedInterval = () => {
    clearInterval(embedInterval);
}

const WF_SRC = (() => {
    const scriptUrl = document.getElementById("ne-wf")?.src;
    if (!scriptUrl) {
        return 'https://loans.nestegg.ai';
    }

    const url = new URL(scriptUrl);
    return url.origin;
})();

window.renderWorkflow = ({ s, o }) => {
    const embedUrl = new URL(WF_SRC);
    if (o) {
        embedUrl.searchParams.set("org", o);
    }

    const embederSearchParams = new URLSearchParams(window.location.search);
    if (embederSearchParams.get("mode") === "test") {
        embedUrl.searchParams.set("mode", "test");
    }

    scheduleRendering({ s, u: embedUrl });
};

window.renderDocUpload = ({ s }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get(WF_TOKEN);
    const docType = searchParams.get(WF_DOC_TYPE);

    let urlPath = '';
    switch (docType) {
        case 'bank':
          urlPath = '/upload-bank-details';
          break;
        case 'id':
          urlPath = '/upload-id-docs';
          break;
        case 'payslips':
          urlPath = '/upload-payslips';
          break;
        default:
          console.error('Invalid doc type provided!');
          return;
    }

    scheduleRendering({ s, u: `${WF_SRC}${urlPath}?${WF_TOKEN}=${token}` });
}

const scheduleRendering = (...args) => {
    if (
        document.readyState === "complete" ||
        document.readyState === "loaded"
    ) {
        renderElement(...args);
        return;
    }

    document.addEventListener("DOMContentLoaded", () => {
        renderElement(...args);
    });
};

const removeElement = (c, s) => {
    const el = c?.querySelector(`.${s}`);
    if (el) {
        console.warn("Workflow iframe already exists, removing!");
        el.parentElement.removeChild(el);
    }
};

const renderElement = ({ s, u }) => {
    let container = s instanceof HTMLElement ? s : document.querySelector(s);
    if (!container) {
        console.warn(
            `WF container element doesn't exist! Plase check the provided selector: ${s}`
        );
        return;
    }

    removeElement(container, WF_CLS);

    const ifr = document.createElement("iframe");

    ifr.setAttribute("class", WF_CLS);
    ifr.setAttribute("id", WF_ID);
    ifr.setAttribute("scrolling", "no");
    ifr.style.border = "none";
    ifr.style.position = "relative";
    ifr.style.width = "100%";


    ifr.src = u;
    container.appendChild(ifr);

    const postEmbedMessage =  () => {
        ifr.contentWindow.postMessage({ type: 'embed', referrer: document.referrer, embedder: location.href }, '*')
    }

    postEmbedMessage();
    createEmbedInterval(postEmbedMessage);

    return ifr;
};

window.renderWorkflowLightbox = (p) => {
    const wfLightbox = document.createElement("div");
    wfLightbox.style.width = "100%";
    wfLightbox.style.height = "100%";
    wfLightbox.style.background = "#000000ee";
    wfLightbox.style.position = "fixed";
    wfLightbox.style.top = "0px";
    wfLightbox.style.left = "0px";
    wfLightbox.style.zIndex = "1000";

    const wfContainer = document.createElement("div");
    wfContainer.style.margin = "50px 10%";
    wfContainer.style.height = "100%";

    const wfClose = document.createElement("div");
    wfClose.style.width = "50px";
    wfClose.style.height = "50px";
    wfClose.style.background = "white";
    wfClose.style.borderRadius = "50%";
    wfClose.style.border = "2px solid rgb(122, 132, 141)";
    wfClose.style.color = "rgb(122, 132, 141)";
    wfClose.style.display = "flex";
    wfClose.style.justifyContent = "center";
    wfClose.style.alignItems = "center";
    wfClose.style.fontSize = "20px";
    wfClose.style.position = "fixed";
    wfClose.style.top = "25px";
    wfClose.style.right = "calc(10% - 25px)";
    wfClose.style.cursor = "pointer";
    wfClose.style.fontFamily = "monospace";
    wfClose.innerText = "X";

    wfClose.addEventListener("click", () => {
        wfLightbox.parentElement.removeChild(wfLightbox);
    });

    wfLightbox.appendChild(wfContainer);
    wfLightbox.appendChild(wfClose);

    window.renderWorkflow({ s: wfContainer, ...p });
    document.body.appendChild(wfLightbox);
};

window.addEventListener("message", (ev) => {
    ev.stopPropagation();
    const eventData = ev?.data;

    const { type, value } = eventData || {};
    switch (type) {
        case "heightchange":
            document.querySelector(
                `.${WF_CLS}`
            ).style.height = `${value.height}px`;
            document
                .getElementById(WF_ID)
                .scrollIntoView({ behavior: "smooth" });
            document.querySelector(`.${WF_CLS}`).style[
                "scroll-margin-top"
            ] = `5rem`;
            break;
        case "embed-ack":
            clearEmbedInterval();
            break;
        default:
            break;
    }
});